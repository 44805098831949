import { Component, OnInit, inject } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { FormBuilder, Validators } from '@angular/forms';
import { NotificacaoService } from '../../../core/services/notificacao/notificacao.service';
import { BaseComponent } from '../../../shared/components/base-component/base-component.component';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { ActivatedRoute, Router } from '@angular/router';
import { VincularInstituicaoParams } from './vincular-instituicao.model';
import { UsuarioService } from '../../usuario/usuario.service';
import { TipoNotificacao } from '../../../core/services/notificacao/notificacao.model';
import { VincularContaService } from '../verificar-instituicao/vincular-conta.service';
import { OrganizacaoVinculoModel, SolicitarVinculoParams, UsuarioVinculoModel } from '../vincular-conta.model';
import { CriptografiaUtil } from '../../../shared/extensions/criptografia.extensions';
import { AutenticacaoService } from '../../autenticacao/autenticacao.service';

@Component({
  selector: 'app-vincular-instituicao',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './vincular-instituicao.component.html',
  styleUrl: './vincular-instituicao.component.scss'
})
export class VincularInstituicaoComponent extends BaseComponent implements OnInit {

  private _fb = inject(FormBuilder);
  private _serviceUsuario = inject(UsuarioService);
  private _router = inject(Router);
  private _activatedRoute = inject(ActivatedRoute);
  private _notificacaoService = inject(NotificacaoService);
  private _vincularContaService = inject(VincularContaService);
  private _autenticacaoService = inject(AutenticacaoService);

  protected form = this._fb.group({
    usuarioId: this._fb.control<string | null>(null, [Validators.required]),
    usuarioEmail: this._fb.control<string | null>(null, [Validators.required, Validators.email]),
    token: this._fb.control<string | null>(null, [Validators.required]),
    instituicaoId: this._fb.control<string | null>(null, [Validators.required]),
    instituicaoNome: this._fb.control<string | null>(null, [Validators.required]),
    instituicaoEmail: this._fb.control<string | null>(null, [Validators.email]),
    pessoaId: this._fb.control<string | null>(null),
    autorizaVinculo: this._fb.control(false, [RxwebValidators.requiredTrue()])
  });

  /**
   * Ao chegar nessa etapa, é obrigatório que o escopo de usuário e instituição já tenha sido
   * identificado, do contrário o usuário será direcionado ao login sem o rastreio do vínculo.
   */
  ngOnInit() {
    this._autenticacaoService.logout(true);
    this.processarQueryParams(this._activatedRoute);
    if (this.scope_ins && this.scope_usr) {
      this.solicitarVinculo();
    } else {
      this._router.navigate(['/autenticacao/login']);
    }
  }

  protected vincular() {
    if (this.isValid(this.form)) {
      const params: VincularInstituicaoParams = {
        usuarioId: this.form.controls.usuarioId.value!,
        instituicaoId: this.form.controls.instituicaoId.value!,
        instituicaoEmail: this.form.controls.instituicaoEmail.value,
        pessoaId: this.form.controls.pessoaId.value,
        token: this.form.controls.token.value!
      };

      this.handleVincularInstituicao(params);
    }
  }

  private handleVincularInstituicao(params: VincularInstituicaoParams) {
    this.form.disable();
    this._serviceUsuario.vincularInstituicao(params)
      .subscribe({
        next: p => {
          this._notificacaoService.show(TipoNotificacao.sucesso, p.message);
          if (this.return_url)
            window.location.href = this.return_url;
          else
            this._router.navigate(['/autenticacao/login']);
        },
        error: (err) => {
          this.form.enable();
          throw err;
        }
      })
  }

  protected solicitarVinculo() {
    this.enableBusy({message: 'Enviando código de verificação, aguarde...'});

    /**
     * Nesta etapa há uma segunda validaçao por segurança, pois os dados de escopo são guardados em base64
     * portanto caso o usuário tente manipular o dado na URL, o acesso será invalidado e ele será direcionado para o login
     * sem rastreio de vínculo.
     */
    const sParams: SolicitarVinculoParams = {
      usuario: CriptografiaUtil.decrypt64<UsuarioVinculoModel>(this.scope_usr!),
      organizacao: CriptografiaUtil.decrypt64<OrganizacaoVinculoModel>(this.scope_ins!),
    };

    if (sParams.usuario && sParams.organizacao) {
      this._vincularContaService.solicitarVinculo(sParams)
        .subscribe({
          next: () => {
            this.form.patchValue({
              usuarioId: sParams.usuario!.id,
              usuarioEmail: sParams.usuario!.email,
              instituicaoId: sParams.organizacao!.id,
              instituicaoEmail: sParams.organizacao!.emaiOrganizacional,
              instituicaoNome: sParams.organizacao!.nome,
              pessoaId: sParams.organizacao!.pessoaId
            });

            this.disableBusy();
          },
          error: (err) => {
            this.setBusyMessage(err.error.message);
            this.setBusyIcon(false);
          }
        })
    } else {
      this._router.navigate(['/autenticacao/login']);
    }
  }
}
