import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { initializeApp } from 'firebase/app';
import { setTheme } from 'ngx-bootstrap/utils';
import { environment } from '../environments/environment';
import { RumService } from './core/services/rum/rum.service';
initializeApp(environment.firebaseConfig);

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  providers: [],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit, OnDestroy {
  private _rum = inject(RumService);

  constructor() {
    this._rum.init();
    setTheme('bs5');
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this._rum.stopSession();
  }
}
